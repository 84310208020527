import * as React from "react"
import Whitepaper from "../models/whitepaper"
const ResponsibilityPage = ({
  title,
  heading,
  img,
  data,
  btntext,
  pageContext: { translate },
}) => {
  return (
    <>
      {
        <div className="responsibilityPage">
          <div className="content">
            <div className="boxouter">
              <div className="greenBox">
                <div className="box">
                  {title && <p className="heading-h3">{title}</p>}
                  <h2 className="heading-h2 semi">{heading}</h2>
                  <Whitepaper
                    pageContext={{ translate }}
                    btnText={btntext}
                    styler="btndefault whiteborderbtn"
                    title={translate.t?.download_the_whitepaper}
                    description={translate.t?.get_more_insights}
                    type="download"
                    submitBtnText={translate.t?.download_now}
                  />
                </div>
              </div>
            </div>
            <div className="list">
              {/* <div className="box box-img">
            <img src={img} alt="Responsibility" />
          </div> */}
              <div className="col-12 col-md-6">
                <div className="box-plain">
                  <img src={img} alt="Responsibility" />
                </div>
              </div>
              {data.map((content, counter) => (
                <div key={counter} className="col-12 col-md-6">
                  <div className="box text-box">
                    <div>
                      <h3 className="heading-h3">{content.title}</h3>
                      <p className="paragraph-p16">{content.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default ResponsibilityPage
