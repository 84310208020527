import * as React from "react"
import Hero from "../roaster/hero"
import Join from "../roaster/join"
import SliderRoaster from "./slider"
import Manage from "./manage"
import InPager from "./winpager"
import ResponsibilityPage from "./responsibility"

import win1 from "../../assets/images/relationshipChart.gif"
import heroimage from "../../assets/images/sustainhero.jpg"
import res from "../../assets/images/home-sustainability.jpg"
import contentHeroImage from "../../assets/images/hero-content-bg.svg"
const SustainabilityPage = ({ pageContext: { translate } }) => {
  const dataResponsible = [
    {
      title: translate.t?.sustainability,
      description: `${translate.t?.by_giving_farmers}\n\n${translate.t?.also_able_to_control}`,
    },
    {
      title: translate.t?.traceability,
      description: translate.t?.all_stakeholders,
    },
    {
      title: translate.t?.environment,
      description: translate.t?.committed_to_environment,
    },
  ]
  return (
    <>
      {
        <div className="sustain_page">
          <div className="roaster_page">
            <div className="content">
              <Hero
                pageContext={{ translate }}
                pagename=""
                title={translate.t?.we_build_sustainable}
                description={translate.t?.sustainable_dev_meets}
                btntext={translate.t?.book_a_demo}
                img={heroimage}
                btnLink=""
                model="contact"
              />
            </div>
            <InPager
              pageContext={{ translate }}
              subtitle={translate.t?.transparent_sustainability}
              title={translate.t?.win_win_relation}
              description={translate.t?.internal_development}
              btntext={translate.t?.download_whitepaper}
              img={win1}
            />
            <ResponsibilityPage
              pageContext={{ translate }}
              title=""
              heading={translate.t?.we_have_responsibility}
              btntext={translate.t?.learn_more_public}
              img={res}
              data={dataResponsible}
            />
            <div className="secondhero">
              <Hero
                pageContext={{ translate }}
                pagename=""
                subTitle={translate.t?.we_empower_farmers}
                description={translate.t?.eraofwe_partners_lsre}
                btntext={translate.t?.book_a_demo}
                btnLink=""
                model="contact"
                img={contentHeroImage}
              />
            </div>
            <div className="roaster_page sustain-slider">
              <SliderRoaster pageContext={{ translate }} />
            </div>
            <Manage
              pageContext={{ translate }}
            
              description={translate.t?.implement_steps_sustainability}
              title={translate.t?.how_manage_sustainability}
            />
            <div className="joiner-second">
              <Join pageContext={{ translate }} />
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default SustainabilityPage
