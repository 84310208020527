import * as React from "react"
import Slider from "react-slick"
import SanAntonioBanner from "../../assets/images/SanAntonio.jpg"
import SipiBanner from "../../assets/images/Sipi.jpg"
import SanAntonio from "../../assets/images/san-antonio.jpg"
import SipiFalls from "../../assets/images/sipi-falls.jpg"
import FazendaBanner from "../../assets/images/FazendaCalifornia.png"
import FazendaCalifornia from "../../assets/images/Fazenda-California.jpg"
import { Link } from "gatsby"

const SliderData = ({ img, typeImg, title, pageName, description, link }) => (
  <div className="box">
    <div className="detail">
      <div className="author">
        <h3 className="heading-h24 text-medium bold">{title}</h3>
        <p className="paragraph-p16 text-medium">{description}</p>
      </div>
      <div>
      <a href={link} target="_blank" className="pagename">
        <img src={typeImg} alt={pageName} />
        <p className="paragraph-p16 text-medium semi">{pageName}</p>
        </a>
      </div>
    </div>
    <a href={link} target="_blank">
    <img className="slider_img" src={img} alt={pageName} />
    </a>
  </div>
)

const SliderRoaster = ({ pageContext: { translate } }) => {
  var settings = {
    slidesToShow: 1,
    centerMode: true,
    centerPadding: "200px",
    arrows: true,
    doys: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    initialSlide: 1,
    onInit: () => {
      return document
        .querySelectorAll(
          `.slider-roster .slick-track .slick-slide[data-index="0"]`
        )[0]
        ?.classList?.add("prev-detect")
    },
    afterChange: function (currentSlide) {
      return document
        .querySelectorAll(
          `.slider-roster .slick-track .slick-slide[data-index="${
            currentSlide - 1
          }"]`
        )[0]
        ?.classList?.add("prev-detect")
    },
    responsive: [
      {
        breakpoint: 1270,
        settings: {
          centerPadding: "130px",
        },
      },
      {
        breakpoint: 1140,
        settings: {
          centerPadding: "80px",
        },
      },
      {
        breakpoint: 992,
        settings: {
          centerPadding: "15%",
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: "0",
        },
      },
    ],
  }

  return (
    <>
      {
        <div className="slider-roster ">
          <div className="content">
            <div className="title">
              <h2>{translate.t?.we_believe_partners}</h2>
              <Link
                to={`${
                  translate.currentLang
                    ? "/" + translate.currentLang + "/"
                    : "/"
                }community/`}
              >
                <button className="btndefault whitebtn">
                  {translate.t?.view_our_community}
                </button>
              </Link>
            </div>
            <Slider {...settings}>
              <SliderData
                description="Since 2020 San Antonio has been working with Toroto to measure and reduce the carbon footprint of our farming methods. By implementing measures such as preserving the rainforest in a sizable part of our estate and allowing another area to lie fallow, we expect to be carbon neutral by 2030. San Antonio is working hard to preserve the rainforest on the estate. A 20-hectare parcel of land is preserved in its pristine state of wilderness. Apart from preserving biodiversity, this also promotes a healthy water cycle, prevents soil erosion and is helping the farm become carbon neutral."
                img={SanAntonioBanner}
                title="Sustainability Is The Cornerstone Of Our Belief System"
                typeImg={SanAntonio}
                pageName="San Antonio"
                link = "https://www.eraofwe.com/estate/san-antonio"
              />
              <SliderData
                description="Aiming to empower traditional, single-family farms and encourage voluntary sustainability standards, 1900 smallholder coffee farmers in the Mount Elgon region of Uganda organized themselves under the umbrella of the Kawacom Uganda Limited- Sipi Falls Scheme.   Over the years, the group has grown organically to 14,000 farms with sustainable methodologies and Organic, Utz, RA and Café certifications."
                img={SipiBanner}
                title="Empowering Traditional Coffee Growers"
                typeImg={SipiFalls}
                pageName="Sipi Falls"
                link = "https://www.eraofwe.com/estate/sipi-falls"
              />
              <SliderData
                description="The cool weather and seasonal variances of our unique microclimate combine with constant scientific improvements to grow truly distinctive beans. From the time Fazenda California was set up in 1903, it has kept itself at the forefront of the latest agronomical research and cutting-edge modernizations. Our extraordinary award-winning coffees, legions of loyal customers, productive land and happy people stand testament to the success of these endeavors. We work proactively to ensure that our farming practices benefit the environment, and that our social endeavours improve our workers’ and community’s quality of life."
                img={FazendaBanner}
                title="Nurturing The Land And People That Nurture Our Beans"
                typeImg={FazendaCalifornia}
                pageName="Fazenda California"
                link = "https://www.eraofwe.com/estate/fazenda-california"
              />
            </Slider>
          </div>
        </div>
      }
    </>
  )
}

export default SliderRoaster
