import * as React from "react"
import Whitepaper from "../models/whitepaper"
const WinPager = ({
  btntext,
  description,
  title,
  img,
  subtitle,
  pageContext: { translate },
}) => {
  return (
    <>
      {
        <div className="win">
          <div className="content">
            {img && <img src={img} alt="winpager" />}
            <div className="detail">
              <p className="paragraph-p4 text-medium subtitle">{subtitle}</p>
              <h2 className="heading-h2 semi text-darker">{title}</h2>
              <p className="paragraph-p1  text-darker">{description}</p>
              <Whitepaper
                pageContext={{ translate }}
                btnText={btntext}
                styler="greenbtn"
                title={translate.t?.download_the_whitepaper}
                description={translate.t?.get_more_insights}
                type="download"
                submitBtnText={translate.t?.download_now}
              />
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default WinPager
