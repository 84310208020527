import * as React from "react"
import m1 from "../../assets/images/icons/m1.svg"
import m3 from "../../assets/images/icons/m3.svg"
import m4 from "../../assets/images/icons/m4.svg"
import m2 from "../../assets/images/icons/sustain1.svg"

const Manage = ({
  description,
  title,
  img,
  pageContext: { translate },
}) => {
  return (
    <>
      {
        <div className="manage">
          <div className="content">
            <div className="first">
              {img && <img src={img} alt="Manage" />}
              <div className="detail">
                <h2 className="heading-h2 semi text-darker">{title}</h2>
                <div>
                  <p className="paragraph-p16 d-flex1  text-medium">
                    {description}
                  </p>
                
                </div>
              </div>
            </div>
            <div className="second check">
              <div className="box">
                <img src={m1} alt="External Certifications" />
                <p
                  alt="External Certifications"
                  className="paragraph-p1  text-darker"
                >
                  {translate.t?.external}
                  <br /> {translate.t?.certifications}
                </p>
              </div>
              <div className="box">
                <img src={m3} alt="Local Presence with Facilitators" />
                <p
                  alt="Local Presence with Facilitators"
                  className="paragraph-p1  text-darker"
                >
                  {translate.t?.local_presence}
                  <br /> {translate.t?.with_facilitators}
                </p>
              </div>
              <div className="box">
                <img src={m4} alt="Quality Assurance Tools" />
                <p
                  alt="Quality Assurance Tools"
                  className="paragraph-p1  text-darker"
                >
                  {translate.t?.quality} <br /> {translate.t?.assurance_tools}
                </p>
              </div>
              <div className="box">
                <img src={m2} alt="Code of Conduct Audits" />
                <p
                  alt="Code of Conduct Audits"
                  className="paragraph-p1  text-darker"
                >
                  {translate.t?.code_of_conduct} <br /> {translate.t?.audits}
                </p>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default Manage
